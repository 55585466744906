import { Link } from 'gatsby'
import React from 'react'
import full01 from '../assets/img/gallery/fulls/01.jpg'
import full02 from '../assets/img/gallery/fulls/02.jpg'
import full03 from '../assets/img/gallery/fulls/03.jpg'
import full04 from '../assets/img/gallery/fulls/04.jpg'
import full05 from '../assets/img/gallery/fulls/05.jpg'
import full06 from '../assets/img/gallery/fulls/06.jpg'
import full07 from '../assets/img/gallery/fulls/07.jpg'
import full08 from '../assets/img/gallery/fulls/08.jpg'
import full09 from '../assets/img/gallery/fulls/09.jpg'
import full10 from '../assets/img/gallery/fulls/10.jpg'
import thumb01 from '../assets/img/gallery/thumbs/01.jpg'
import thumb02 from '../assets/img/gallery/thumbs/02.jpg'
import thumb03 from '../assets/img/gallery/thumbs/03.jpg'
import thumb04 from '../assets/img/gallery/thumbs/04.jpg'
import thumb05 from '../assets/img/gallery/thumbs/05.jpg'
import thumb06 from '../assets/img/gallery/thumbs/06.jpg'
import thumb07 from '../assets/img/gallery/thumbs/07.jpg'
import thumb08 from '../assets/img/gallery/thumbs/08.jpg'
import thumb09 from '../assets/img/gallery/thumbs/09.jpg'
import thumb10 from '../assets/img/gallery/thumbs/10.jpg'
import pic01 from '../assets/img/pic01.jpeg'
import pic02 from '../assets/img/pic02.jpg'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'

const images01 = [
  {
    id: '1',
    source: full01,
    thumbnail: thumb01,
    caption: 'Panneau publicitaire BACKLIGHT AGADIR',
    description: 'Panneau et enseignes publicitaires en lettres reliefs',
    landscape: true,
  },
  {
    id: '2',
    source: full02,
    thumbnail: thumb02,
    caption: 'Panneau publicitaire BACH AGADIR',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou en plexiglas lumineux',
  },
  {
    id: '3',
    source: full03,
    thumbnail: thumb03,
    caption: 'Panneau publicitaire ALUCOBOND AGADIR',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en forex ou plexiglas lumineux',
  },
  {
    id: '4',
    source: full04,
    thumbnail: thumb04,
    caption: 'Panneau publicitaire DÉCOUPE LASER AGADIR',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou plexiglas avec lumière en Led',
    landscape: true,
  },
]

const images02 = [
  {
    id: '5',
    source: full05,
    thumbnail: thumb05,
    caption: 'Panneau publicitaire PLÉXIGLASS AGADIR',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en aluminium avec lumière en neon',
  },
  {
    id: '6',
    source: full06,
    thumbnail: thumb06,
    caption: 'Panneau publicitaire LED AGADIR',
    description: 'Panneau lumineux avec logo découpé en alucobande ou plexiglas',
  },
  {
    id: '7',
    source: full07,
    thumbnail: thumb07,
    caption: 'Panneau publicitaire ROUTE AGADIR',
    description: 'les panneaux publicitaires lumineux Agadir',
    landscape: true,
  },
]

const images03 = [
  {
    id: '8',
    source: full08,
    thumbnail: thumb08,
    caption: 'Panneau publicitaire TOTEM AGADIR',
    description: 'Signalitique routier Agadir',
  },
  {
    id: '9',
    source: full09,
    thumbnail: thumb09,
    caption: 'Panneaux publicitaire NÉON AGADIR',
    description: 'Agence d\'installation des panneaux publicitaire Agadir',
  },
  {
    id: '10',
    source: full10,
    thumbnail: thumb10,
    caption: 'Panneaux publicitaire LUMINEUX AGADIR',
    description: 'IMPRIMER EN LIGNE Agadir.',
    landscape: true,
  },
]

const IndexPage = () => (
  <Layout>
    <section className="intro">
      <header>
        <h1>SUD Impression</h1>
        <p className="slogan">
          Agence de communication Publicité{' '}
          <br/>
          <Link to="gallery-sud-impression-agadir">VOIR NOTRE TRAVAIL</Link>
          {/* <a href="https://html5up.net"></a> */}
        </p>
        <ul className="actions">
          <li>
            <a href="#first" className="arrow scrolly">
              <span className="label">Next</span>
            </a>
          </li>
        </ul>
      </header>
      <div className="content">
        <span className="image fill" data-position="center">
          <img src={pic01} alt="" />
        </span>
      </div>
    </section>

    <section id="first">
      <header>
        <h2>Impressions Papier</h2>
      </header>
      <div className="content">
        <p>
          Le parc machines dans notre agence publicité <strong>Agadir Maroc</strong>, dispose de machines a impressions d’une très bonne qualité telle que Minolta et Ricoh, Nous imprimons sur toute sorte de papiers et sur tous les grammages qui existent sur le marché. nous imprimons au compte de nos clients des flyers A5, des affiches A3 et A4, des attestations cartonnées, des cartes visites, des menus, des brochures etc ...
        </p>
        <strong>
          <Link to="gallery-sud-impression-agadir">NOS RÉALISATIONS</Link>
        </strong>
        <span className="image main">
          <img src={pic02} alt="" />
        </span>
      </div>
    </section>

    <section>
      <header>
        <h2>DÉCOUVREZ NOS PRODUITS</h2>
      </header>
      <div className="content">
        <p>
          Accroître la<strong> Notoriété</strong> de votre Entreprise avec des Produits Promotionnels.
        </p>
        <ul className="feature-icons">
          <li className="icon solid fa-print color-black">Carterie</li>
          <li className="icon solid fa-sticky-note color-black">Publicité</li>
          <li className="icon solid fa-copy color-black">Papeterie</li>
          <li className="icon solid fa-scroll color-black">Supports Publicitaires</li>
          <li className="icon solid fa-newspaper color-black">Marquage Véhicule</li>
          <li className="icon solid fa-cube color-black">Lettres 3D</li>
        </ul>
        <p>
        Les produits promotionnels ou les articles de marque peuvent constituer une option incroyable pour les petites entreprises comme pour les grandes. Avec le matériel promotionnel adéquat, vous pouvez améliorer vos efforts de marketing en ligne et hors ligne grâce à des solutions de promotion de marchandises mémorables et rentables pour votre visibilité.
        </p>
        <strong>
          <Link to="gallery-sud-impression-agadir">VOIR NOTRE TRAVAIL</Link>
        </strong>
      </div>
    </section>

    <section>
      <header>
        <h2>PANNEAU PUBLICITAIRE AGADIR</h2>
      </header>
      <div className="content">
        <p>
          <strong>Agence d'installation des panneaux publicitaire Agadir</strong> nos équipes préparent les structures métalliques de vos panneaux en se déplaçant vers vos locaux, boutiques ou entreprises, pour prendre minutieusement les mesures nécessaires et pour avoir une idée sur les formes à utiliser pour vos panneaux publicitaires, après ceci nous vous préparons une modélisation de vos panneaux publicitaires que ce soit pour la forme, le design et le contenu de votre panneau.
        </p>

        <section>
          <header>
            <h3 className="color-base">Conception</h3>
            <p className="color-white">
            Nos cartes de visite sont toujours pas chères, de haute qualité et vous offrent une large gamme de papier et de matériaux.
            </p>
          </header>
          <div className="content">
            <Gallery
              images={images01.map(
                ({
                  id,
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                }) => ({
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                })
              )}
            />
            <br/>
            <strong>
              <Link to="gallery-sud-impression-agadir">VOIR PLUS</Link>
            </strong>
          </div>
        </section>

        <section>
          <header>
            <h3 className="color-base">Impression</h3>
            <p className="color-white">
            Démarquez-vous et assurez-vous une visibilité maximale au meilleur prix grâce à l’impression de flyers publicitaires !
            <br/>
            Complimentez vos délicieux repas avec des cartes de menu personnalisées. Assurez-vous que l’expérience culinaire de vos clients est parfaite à chaque étape grâce à vos propres menus imprimés.
            </p>
          </header>
          <div className="content">
            <Gallery
              images={images02.map(
                ({
                  id,
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                }) => ({
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                })
              )}
            />
            <br/>
            <strong>
              <Link to="gallery-sud-impression-agadir">VOIR PLUS</Link>
            </strong>
          </div>
        </section>

        <section>
          <header>
            <h3 className="color-base">Panneaux de chantiers</h3>
            <p className="color-white">
              Notre Agence SUD Impression Pubilicité et panneaux publicitaires propose au compte de ses cliens le service de decoupe CNC, decoupe Laser et decoupe sur Adhésifs.
            </p>
          </header>
          <div className="content">
            <Gallery
              images={images03.map(
                ({
                  id,
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                }) => ({
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                })
              )}
            />
            <br/>
            <strong>
              <Link to="gallery-sud-impression-agadir">VOIR PLUS</Link>
            </strong>
          </div>
        </section>
      </div>
    </section>

    {/* <section>
      <header>
        <h2>Duis sed adpiscing veroeros amet</h2>
      </header>
      <div className="content">
        <p>
          <strong>Proin tempus feugiat</strong> sed varius enim lorem
          ullamcorper dolore aliquam aenean ornare velit lacus, ac varius enim
          lorem ullamcorper dolore.
        </p>
        <ul className="actions">
          <li>
            <a href="#" className="button primary large">
              Get Started
            </a>
          </li>
          <li>
            <a href="#" className="button large">
              Learn More
            </a>
          </li>
        </ul>
      </div>
    </section> */}

    <section>
      <header>
        <h2>Connectez avec nous</h2>
      </header>
      <div className="content">
        <p>
          Vous n'avez pas pu trouver une réponse à vos questions dans norte page web,voulez-vous
juste nous <strong>laisser un message.</strong>
        </p>
        <form>
          <div className="fields">
            <div className="field half">
              <input type="text" name="name" id="name" placeholder="Name" />
            </div>
            <div className="field half">
              <input type="email" name="email" id="email" placeholder="Email" />
            </div>
            <div className="field">
              <textarea
                name="message"
                id="message"
                placeholder="Message"
                rows="7"
              ></textarea>
            </div>
          </div>
          <ul className="actions">
            <li>
              <input
                type="submit"
                value="Send Message"
                className="button large primary"
              />
            </li>
          </ul>
        </form>
        {/* <iframe width="100%" height="600" src="https://maps.google.com/maps?width=100%&amp;height=200&amp;hl=en&amp;coord=52.70967533219885, -8.020019531250002&amp;q=1%20Grafton%20Street%2C%20Dublin%2C%20Ireland&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe> */}
        {/* <p>
          Vous n'avez pas pu trouver une réponse à vos questions dans norte page web,voulez-vous
juste nous <strong>laisser un message.</strong>
        </p> */}
      </div>
      <footer>
        <ul className="items">
          <li>
            <h3 className="color-base">Email</h3>
            <a href="mailto:sudimpression20@gmail.com">sudimpression20@gmail.com</a>
          </li>
          <li>
            <h3 className="color-base">Téléphone</h3>
            <a href="tel:0641826968">(+212) 6 41 82 69 68</a>
          </li>
          <li>
            <h3 className="color-base">Adresse</h3>
            <span className="color-base">Rue yaakoub El mansour N°7 Quartier Talborjt Agadir</span>
          </li>
          <li>
            <h3 className="color-base">Autre part</h3>
            <ul className="icons">
              {/* <li>
                <a href="#" className="icon brands fa-twitter">
                  <span className="label">Twitter</span>
                </a>
              </li> */}
              <li>
                <a href="https://www.facebook.com/sudimpression" className="icon brands fa-facebook-f">
                  <span className="label">Facebook</span>
                </a>
              </li>
              {/* <li>
                <a href="#" className="icon brands fa-instagram">
                  <span className="label">Instagram</span>
                </a>
              </li>
              <li>
                <a href="#" className="icon brands fa-linkedin-in">
                  <span className="label">LinkedIn</span>
                </a>
              </li>
              <li>
                <a href="#" className="icon brands fa-github">
                  <span className="label">GitHub</span>
                </a>
              </li>
              <li>
                <a href="#" className="icon brands fa-codepen">
                  <span className="label">Codepen</span>
                </a>
              </li> */}
            </ul>
          </li>
        </ul>
        <iframe width="100%" height="300" src="https://maps.google.com/maps?width=100%&amp;height=300&amp;hl=en&amp;coord=30.4246891, -9.5925699&amp;q=SUD IMPRESSION - AGADIR, Rue Yacoub El Mansour, Agadir&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      </footer>
    </section>

    <div className="copyright">
      &copy; All rights reserved. Made by:{' '}
      <a target="_blank" href="https://waihas.com">Waihas</a>.
    </div>
  </Layout>
)

export default IndexPage
